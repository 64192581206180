
































































































































import { Component, Vue } from 'vue-property-decorator';
import OnceButton from '@/components/OnceButton.vue';
import * as api from '@/gen/api';
import label from '@/resources/labels/p0108label.json';
import { AxiosError } from 'axios';

@Component({
  components: {
    'once-button': OnceButton
  }
})
export default class P0108 extends Vue {
  buttonLoading = false;
  newEmail = '';
  newEmailConfirm = '';
  readonly label: any = label;
  errorMsg = '';

  isNoticeEmailCarrier: boolean = false;

  async submitRequestUpdateEmail() {
    const result = await this.$validator.validateAll();
    if (!result) {
      return;
    }
    if (this.newEmail == this.$auth.user.email) {
      // 新しいメールアドレスがログインユーザのメールアドレスと重複
      this.errorMsg = this.$msg.get('2000520');
      return;
    }
    this.buttonLoading = true;
    this.errorMsg = '';

    const res = await this.postRequestForUpdateCurrentUserEmail();
    if (res === undefined) {
      // error handled
      return;
    }
    // success
    this.$router.push({ name: 'update-email-ok' });
  }

  async postRequestForUpdateCurrentUserEmail() {
    const config = await this.$getConfigWithToken(this.$auth);
    const curUserApi = new api.CurrentUserApi(config);
    return await curUserApi
      .postRequestForUpdateCurrentUserEmail({
        mail_address: this.newEmail
      })
      .catch((err: AxiosError) => {
        this.handleRequestForUpdateEmailErr(err);
        this.buttonLoading = false;
      });
  }

  handleRequestForUpdateEmailErr(err: AxiosError) {
    if (err.response) {
      switch (err.response.status) {
        case 400:
          if (err.response.data && err.response.data.code === 40015) {
            this.errorMsg = this.$msg.get('2000520');
            break;
          } else {
            this.errorMsg = this.$msg.get('2000012');
            break;
          }
        case 401:
          this.$tokenErrHandler(err);
          break;
        default:
          // 想定されている403,500（5桁コード50000）と、それ以外のエラーが意図せず返った場合
          this.errorMsg = this.$msg.get('2000011');
      }
    }
  }

  checkCarrierEmail() {
    const carrierDomains = ['@docomo.ne.jp', '@ezweb.ne.jp'];
    this.isNoticeEmailCarrier = carrierDomains.some(domain =>
      this.newEmail.endsWith(domain)
    );
  }

  /**
   * 戻るボタン押下
   */
  back(): void {
    this.$router.push({ name: 'account-info' });
  }
}
